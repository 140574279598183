<template>
  <main class="app__dekstop">
    <router-view :key="$route.fullPath"></router-view>
    <whatsapp-button/>
    <check-phone-number/>
  </main>
</template>

<script>
import whatsappButton from '@/views/WhatsappButton'
import checkPhoneNumber from '@/views/CheckPhoneNumber'
import { initGoogleSdk, initFacebookSdk, initGoogleAnalytics, initFacebookPixel } from './_helpers'
export default {
  components: { whatsappButton, checkPhoneNumber },
  watch:{
    $route (to){
      initGoogleAnalytics().pageViewEvent('global_ga', to.fullPath)
      initFacebookPixel().pageViewEvent(process.env.VUE_APP_PIXEL_TRACKING_ID)
    }
  },
  beforeMount () {
    initGoogleAnalytics().init().then(() => {
      initGoogleAnalytics().create(process.env.VUE_APP_GA_TRACKING_ID, 'global_ga')
      initGoogleAnalytics().pageViewEvent('global_ga')
    }).catch(err => {
      if (process.env.NODE_ENV !== 'production') {
        console.log(err)
      }
    })
    initFacebookPixel().init().then(() => {
      if (this.$store.getters['trackingCodes/findOne']({type: 'pixel', id: process.env.VUE_APP_PIXEL_TRACKING_ID}) === null) {
        this.$store.dispatch('trackingCodes/setCode', {type: 'pixel', id: process.env.VUE_APP_PIXEL_TRACKING_ID})
        initFacebookPixel().create(process.env.VUE_APP_PIXEL_TRACKING_ID)
      }
      initFacebookPixel().pageViewEvent(process.env.VUE_APP_PIXEL_TRACKING_ID)
    }).catch(err => {
      if (process.env.NODE_ENV !== 'production') {
        console.log(err)
      }
    })
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    initGoogleSdk().then(() => {})
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    initFacebookSdk().then(() => {})
  }
}
</script>

<style lang="scss">
@import '~styles/index.scss';
</style>
