export function initGoogleSdk() {
  const GoogleAuthScope = 'https://www.googleapis.com/auth/userinfo.email'
  return new Promise((resolve, reject) => {
    window.gapi.load('client:auth2', function () {
      try {
        // eslint-disable-next-line prettier/prettier
        window.gapi.client.init({
          // eslint-disable-next-line @typescript-eslint/camelcase
          api_key: process.env.VUE_APP_API_GOOGLE_CLIENT_SECRET,
          // eslint-disable-next-line @typescript-eslint/camelcase
          client_id: process.env.VUE_APP_API_GOOGLE_CLIENT_ID,
          scope: GoogleAuthScope
          //   discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest']
        })

        resolve(window.api)
      } catch (error) {
        reject(error.error)
      }
    })
  })
}

export function initFacebookPixel() {
  return {
    init: () => {
      return new Promise((resolve, reject) => {
        try {
          if (typeof fbq !== 'function') {
            // eslint-disable-next-line
            !function (e, n, t, o, c, s, a) { e.fbq || (c = e.fbq = function () { c.callMethod ? c.callMethod.apply(c, arguments) : c.queue.push(arguments) }, e._fbq || (e._fbq = c), c.push = c, c.loaded = !0, c.version = "2.0", c.queue = [], (s = n.createElement(t)).async = !0, s.src = "https://connect.facebook.net/en_US/fbevents.js", (a = n.getElementsByTagName(t)[0]).parentNode.insertBefore(s, a)) }(window, document, "script");
            // eslint-disable-next-line
            fbq.disablePushState = true;
          }
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    },
    create: (trackingId) => {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line
        fbq('init', trackingId)
        resolve()
      })
    },
    pageViewEvent: (trackingId = null) => {
      return new Promise((resolve, reject) => {
        try {
          // eslint-disable-next-line
          fbq('trackSingle', trackingId, 'PageView')
          /* if (trackingId === null)  {
            // eslint-disable-next-line
            fbq('track', 'PageView')
          } else {
            // eslint-disable-next-line
            fbq('trackSingle', trackingId, 'PageView')
          } */
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    }
  }
}


export function initGoogleAnalytics() {
  return {
    init: () => {
      return new Promise((resolve, reject) => {
        try {
          if (typeof ga !== 'function') {
            // eslint-disable-next-line
            (function (i, s, o, g, r, a, m) { i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () { (i[r].q = i[r].q || []).push(arguments) }, i[r].l = 1 * new Date(); a = s.createElement(o), m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m) })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga')
          }
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    },
    create: (trackingId, trackingName) => {
      return new Promise((resolve, reject) => {
        try {
          // eslint-disable-next-line
          ga(function () {
            // eslint-disable-next-line
            if (typeof ga.getByName(trackingName) !== 'object') {
              // eslint-disable-next-line
              ga('create', trackingId, { name: trackingName })
              // eslint-disable-next-line
              ga(trackingName + '.require', 'displayfeatures')
            }
          })
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    },
    pageViewEvent: (trackingName, pageUrl = null) => {
      return new Promise((resolve, reject) => {
        try {
          if (pageUrl !== null) {
            // eslint-disable-next-line
            ga(trackingName + '.set', 'page', pageUrl)
          }
          // eslint-disable-next-line
          // ga(trackingName + '.require', 'displayfeatures')
          // eslint-disable-next-line
          ga(trackingName + '.send', 'pageview')
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    }
  }
}

export function imageCdn(str, type) {
  if (process.env.VUE_APP_USE_IMG_CDN) {
    if (str === null) {
      return null
    }
    // str = str.replace('http://new.api.amazingsedekah.com/uploads', 'https://adt.imgix.net');
    // str = str.replace('http://www.new.api.amazingsedekah.com/uploads', 'https://adt.imgix.net');
    // str = str.replace('http://amazingsedekah.com/storage/uploads', 'https://adt.imgix.net');
    // str = str.replace('http://www.amazingsedekah.com/storage/uploads', 'https://adt.imgix.net');
    // str = str.replace('http://assets.amazingsedekah.com/uploads', 'https://adt.imgix.net');
    // str = str.replace('http://www.assets.amazingsedekah.com/uploads', 'https://adt.imgix.net');
    // str = str.replace('http://assets.amazingsedekah.com/uploads', 'https://adt.imgix.net');
    // str = str.replace('http://www.assets.amazingsedekah.com/uploads', 'https://adt.imgix.net');
    if (type === 'banner') {
      return str + '?auto=compress,format&cs=tinysrgb&fm=pjpg&fit=clip&w=534'
    } else if (type === 'campaign-list') {
      return str + '?w=300&fit=crop&auto=format,compress'
    } else if (type === 'campaign-detail') {
      return str + '?ar=16:9&w=664&auto=format,compress'
    } else if (type === 'other') {
      return str = str.replace('/assets.amazingsedekah.com/uploads/', '/adt.imgix.net/');
    } else {
      return str
      // return str.replace('https://adt.imgix.net', 'http://assets.amazingsedekah.com/uploads');
    }
  } else {
    return str
  }
}

export function initFacebookSdk() {
  return new Promise(resolve => {
    // wait for facebook sdk to initialize before starting the vue app
    window.fbAsyncInit = function () {
      /* eslint-disable */
      FB.init({
        appId: process.env.VUE_APP_API_FB_APP_ID,
        cookie: true,
        autoLogAppEvents: true,
        xfbml: true,
        xfbml: true,
        version: 'v9.0'
      });
      resolve();
      // auto authenticate with the api if already logged in with facebook
      /* eslint-disable */
      // FB.getLoginStatus(({ authResponse }) => {
      //     console.log('getLoginStatus');
      //     console.log(authResponse);
      //     resolve();
      // });
    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  });
}

export function serializeErrorMessage(error) {
  if (typeof error.response !== 'undefined') {
    if (typeof error.response.data.errors !== 'undefined') {
      const validationMessages = error.response.data.errors
      if (typeof validationMessages === 'string') {
        return validationMessages
      } else if (Object.keys(validationMessages).length) {
        let errorMessages = '<ul>'
        for (let i = 0; i < Object.keys(validationMessages).length; i++) {
          errorMessages = errorMessages + '<li>' + validationMessages[Object.keys(validationMessages)[i]] + '</li>'
        }
        errorMessages = errorMessages + '</ul>'
        return errorMessages
      } else {
        return error.response.data
      }
    } else if (typeof error.response.data.message !== 'undefined') {
      return error.response.data.message === 'The user credentials were incorrect.' || error.response.data.message === 'Client authentication failed' ? 'Kombinasi email/username & password salah' : error.response.data.message
    } else if (typeof error.response.data !== 'undefined') {
      return error.response.data
    }
  } else if (typeof error === 'string') {
    return error
  } else {
    return 'something wrong, silahkan coba kembali.'
  }
}