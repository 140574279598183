<template>
  <!-- eslint-disable -->
  <div class="wa-float" v-bind:class="{ 'have-bottom-nav':  currentRouteName === 'Home' }">
    <span class="help-text">Anda Butuh Bantuan?</span>
    <a :href="'https://api.whatsapp.com/send?phone=' + waNumber + '&text=Assalamu%27alaikum!%20Selamat%20datang%20di%20Customer%20Service%20Amazing%20Sedekah.%20Ada%20yang%20bisa%20kami%20bantu?'" class="wa-button" target="_blank">
      <span>
        <i class="fab fa-whatsapp"/>
      </span>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      waNumber: process.env.VUE_APP_WA_CS_NUMBER
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    }
  }
}
</script>
