import { createApp } from '@/app'

import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

const { app, router } = createApp()
router.isReady().then(() => {
  app.mount('#app', true)
})
