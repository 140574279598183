import swal from 'sweetalert2'
import { serializeErrorMessage } from '../../_helpers/index'

export default {
  actions: {
    // eslint-disable-next-line
    // welcome ({state}, message) {
    //   this.$notify.info({
    //     title: 'Halo',
    //     message,
    //   })
    // },
    // eslint-disable-next-line
    success ({state}, message) {
      swal.fire({
        icon: 'success',
        title: 'Berhasil',
        confirmButtonText: 'OK',
        confirmButtonColor: '#C5601A',
        text: message
      })
    },
    // eslint-disable-next-line
    // addToCart ({state}, message) {
    //   this.$notify.info({
    //     title: 'Order Baru',
    //     message,
    //   })
    // },
    // eslint-disable-next-line
    // info ({state}, message) {
    //   this.$notify.info({
    //     title: 'Info',
    //     message,
    //   })
    // },
    // eslint-disable-next-line
    error ({state}, message) {
      swal.fire({
        icon: 'error',
        title: 'Oops...',
        confirmButtonText: 'OK',
        confirmButtonColor: '#C5601A',
        text: serializeErrorMessage(message)
      })
    }
    // eslint-disable-next-line
    // clear () {
    //   this.$notify.destroy()
    // }
  }
}