import { createSSRApp } from 'vue'
import VueGtm from 'vue-gtm'
import App from '@/App.vue'
import createRouter from './router/router.js'
import store from './store'
import axios from 'axios'
import moment from 'moment-timezone'
import swal from 'sweetalert2'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _ from 'lodash'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'sweetalert2/dist/sweetalert2.min.js'

export const createApp = () => {
//   const rootComponent = {
//     render: () => h(App),
//     components: { App }
//   }
  const app = createSSRApp(App)
   const router = createRouter()
  moment.tz.setDefault('Asia/Jakarta')
  moment.locale('id-ID')
  app.config.globalProperties.$formatPrice = value => {
    if (value === null) {
      return 0
    }
    return new Intl.NumberFormat('id-ID', { minimumFractionDigits: value % 1 > 0 ? 2 : 0 }).format(
      value
    )
  }
  app.config.globalProperties.$http = axios
  app.config.globalProperties.$moment = moment
  app.config.globalProperties.$swal = swal
  app.config.globalProperties.urlAdmin = 'https://dash.amazingsedekah.com/'
  app.use(store)
  app.use(router)
/*
  app.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    defer: false,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
  })
 */
  return { app, router }
}
