<template>
    <!-- eslint-disable -->
    <div></div>
</template>

<script>
import { serializeErrorMessage } from '@/_helpers'
import swal from 'sweetalert2'

export default {
    mounted() {
        /* eslint-disable */
        if (this.$store.getters['account/accountData'] !== null && this.$store.getters['account/accountData'].detail.phone_number === null) {
            swal.fire({
                customClass: {
                    container: 'lanjutkan-form'
                },
                text: 'Untuk dapat melanjutkan, silahkan mengisi no hp anda.',
                input: 'tel',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: false,
                confirmButtonText: 'Simpan',
                showLoaderOnConfirm: true,
                preConfirm: (login) => {
                    if (login === null || login.trim() === '') {
                        swal.showValidationMessage(
                            `No HP Wajib Diisi!`
                        )
                        return false
                    }
                    return this.$http.post(`${process.env.VUE_APP_API_URL}me/phone-number`, {
                        'phone_number': login,
                    }, { headers: { Authorization: `Bearer ${window.localStorage.getItem('access_token')}`} })
                    .then(response => {
                        return response
                    }).catch(error => {
                        swal.showValidationMessage(serializeErrorMessage(error))
                    })
                },
                allowOutsideClick: false
                }).then((result) => {
                    return this.$http.get(process.env.VUE_APP_API_URL + 'me', {
                        headers: { Authorization: 'Bearer ' + window.localStorage.getItem('access_token') }
                    }).then(response => {
                        window.localStorage.setItem('account_data', JSON.stringify(response.data))
                        this.$store.dispatch('account/setAccountData', JSON.stringify(response.data))
                        return response.data
                    }).catch(error => {
                        console.error(error)
                    })
                })

        }
        /* eslint-enable */
    }
}
</script>
