import { createRouter, createWebHistory, createMemoryHistory } from 'vue-router'
import store from '../store/index'
import axios from 'axios'

const Home = () => import(/* webpackPreload:true, webpackChunkName: "home" */ '../views/Home.vue')
const DetailCampaign = () =>
  import(
    /* webpackPreload:true, webpackChunkName: "detail-campaign" */ '../views/DetailCampaign.vue'
  )
const CategoryCampaign = () =>
  import(
    /* webpackPreload:true, webpackChunkName: "category-campaign" */ '../views/CategoryCampaign.vue'
  )
const Transaction = () =>
  import(/* webpackPreload:true, webpackChunkName: "transaction" */ '../views/Transaction.vue')
const IntruksiPembayaran = () =>
  import(
    /* webpackPreload:true,webpackChunkName: "intruksi-pembayaran" */ '../views/IntruksiPembayaran.vue'
  )
const DetailPembayaran = () =>
  import(
    /* webpackPreload:true, webpackChunkName: "detail-pembayaran" */ '../views/DetailPembayaran.vue'
  )
const DetailDonasi = () =>
  import(/* webpackPreload:true, webpackChunkName: "detail-donasi" */ '../views/DetailDonasi.vue')
const Donatur = () =>
  import(/* webpackPreload:true, webpackChunkName: "donatur" */ '../views/Donatur.vue')
const DonasiSaya = () =>
  import(/* webpackPreload:true, webpackChunkName: "donasi-saya" */ '../views/DonasiSaya.vue')
const Login = () =>
  import(/* webpackPreload:true, webpackChunkName: "login" */ '../views/Login.vue')
const Register = () =>
  import(/* webpackPreload:true, webpackChunkName: "register" */ '../views/Register.vue')
const Search = () =>
  import(/* webpackPreload:true, webpackChunkName: "search" */ '../views/Search.vue')
const Campaign = () =>
  import(/* webpackPreload:true, webpackChunkName: "campaign" */ '../views/Campaign.vue')
const MyAccount = () =>
  import(/* webpackPreload:true, webpackChunkName: "my-account" */ '../views/Profile/MyAccount.vue')
const Profile = () =>
  import(/* webpackPreload:true, webpackChunkName: "profile" */ '../views/Profile/Profile.vue')
const ChangePassword = () =>
  import(
    /* webpackPreload:true, webpackChunkName: "change-password" */ '../views/Profile/ChangePassword.vue'
  )
const TermCondition = () =>
  import(/* webpackPreload:true, webpackChunkName: "term-codition" */ '../views/TermCondition.vue')
const Privacy = () =>
  import(/* webpackPreload:true, webpackChunkName: "privacy" */ '../views/Privacy.vue')
const About = () =>
  import(/* webpackPreload:true, webpackChunkName: "about" */ '../views/About.vue')
const RegisterLembaga = () =>
  import(
    /* webpackPreload:true, webpackChunkName: "register-lembaga" */ '../views/RegisterLembaga.vue'
  )
const ForgotPassword = () =>
  import(
    /* webpackPreload:true, webpackChunkName: "forgot-password" */ '../views/ForgotPassword.vue'
  )
const ResetPassword = () =>
  import(/* webpackPreload:true, webpackChunkName: "reset-password" */ '../views/PasswordReset.vue')

const PageNotFound = () => import(/* webpackChunkName: "404" */ '../views/404.vue')

const isServer = typeof window === 'undefined'

// const history = isServer ? createMemoryHistory() : createWebHistory()

const routes = [
  {
    path: '/logout',
    name: 'Logout',
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresGuest: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      requiresGuest: true
    }
  },
  {
    path: '/register-lembaga',
    name: 'RegisterLembaga',
    component: RegisterLembaga
  },
  {
    path: '/my-account/:name',
    name: 'MyAccount',
    component: MyAccount
  },
  {
    path: '/profile/:name',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/change-password/:name',
    name: 'ChangePassword',
    component: ChangePassword
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:slug',
    name: 'Detail Campaign',
    component: DetailCampaign
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    component: Campaign
  },
  {
    path: '/category/:slug',
    name: 'Category Campaign',
    component: CategoryCampaign
  },
  {
    path: '/transaksi/:uuid',
    name: 'Transaksi',
    component: Transaction
  },
  {
    path: '/intruksi-pembayaran/:code',
    name: 'Intruksi Pembayaran',
    component: IntruksiPembayaran
  },
  {
    path: '/detail-pembayaran/:code',
    name: 'Detail Pembayaran',
    component: DetailPembayaran
  },
  {
    path: '/detail-donasi/:code',
    name: 'Detail Donasi',
    component: DetailDonasi
  },
  {
    path: '/donatur/:slug',
    name: 'Donatur',
    component: Donatur
  },
  {
    path: '/donasi-saya',
    name: 'Donasi Saya',
    component: DonasiSaya,
    meta: { requiresAuth: true }
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/term-condition',
    name: 'TermCondition',
    component: TermCondition
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/reset-password',
    name: 'PasswordReset',
    component: ResetPassword
  },
  { path: '/404', component: PageNotFound }
]

const router = createRouter({
  history: isServer ? createMemoryHistory() : createWebHistory(),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
  }
})

if (store.getters['account/isLoggedIn']) {
  store.dispatch('account/saveTokens', {
    tokens: store.getters['account/tokens'],
    accountData: null,
    isLocalLogin: true
  })
}

const isLoggedIn = store.getters['account/isLoggedIn']
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth === true)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresGuest === true)) {
    if (isLoggedIn) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

if (isLoggedIn) {
  axios.interceptors.response.use(undefined, function(err) {
    if (err.response === undefined) {
      console.log('testing')
    } else {
      console.log(err.response)
      if (err.response.status === 401 && err.response.statusText === 'Unauthorized') {
        console.log(true)
        store
          .dispatch('account/refreshToken')
          .then(() => {
            err.config.__isRetryRequest = true
            // axios(err.config).then(resolve, reject)
            location.reload()
          })
          .catch(() => {
            return store.dispatch('account/logout').then(() => {
              window.location.href = '/login'
              // router.push('/app/sessions/signIn')
            })
          })
      } else if (err.response.status === 404) {
        router.push('/404')
      }
      return Promise.reject(err)
    }
  })
}

// export default router

export default function() {
  return router
}
