import axios from 'axios'

export default {
  state: {
    codes: []
  },
  getters: {
    findAll: state => {
      return state.codes
    },
    findOne: (state) => (param) => {
      const filtered = state.codes.filter((code) => {
        return code.type === param.type && code.id === param.id
      })
      if (filtered.length > 0) {
        return filtered[0]
      }
      return null
    }
  },
  mutations: {
    setCode(state, data) {
      state.codes.push(data)
    }
  },
  actions: {
    setCode({ commit }, data) {
      commit('setCode', data);
    }
  }
}
