export default{
    state: {
        snap: null
    },
    getters: {
        findSnap: state => {
            return state.snap
        }
    },
    mutations:{
        setSnap(state, data){
            state.snap = data
        }
    },
    actions: {
        setSnap({commit}, data){
            commit('setSnap',data)
        },
        fetchSnap({commit,getters}) {
            return new Promise((resolve,reject) => {
                if (getters.findSnap === null) {
                    const script = document.createElement('script')
                    script.src = 'https://app.midtrans.com/snap/snap.js'
                    script.setAttribute('data-client-key',process.env.VUA_APP_KEY_MIDTRANS)
                    document.head.appendChild(script)
                    commit('setSnap',script)
                    return resolve(script)
                }else{
                    return resolve(getters.findSnap)
                }
            })
        }
    }
}