import axios from 'axios'

export default {
  state: {
    categories: []
  },
  getters: {
    findAll: state => {
      return state.categories
    },
    findOne: (state) => (param) => {
      const filtered = state.categories.filter((category) => {
        return category[param.key] === param.value
      })
      if (filtered.length > 0) {
        return filtered[0]
      }
      return null
    }
  },
  mutations: {
    setCategories(state, data) {
      state.categories = data
    }
  },
  actions: {
    setCategories({ commit }, data) {
      commit('setCategories', data);
    },
    fetchCategories({ commit, getters }) {
      return new Promise((resolve, reject) => {
        if (!getters.findAll.length) {
          return axios.get(`${process.env.VUE_APP_API_URL}campaign-categories?order=cat_position&ascending=1&limit=100`).then( (response) => {
            commit('setCategories', response.data.data)
            resolve(response.data.data)
          }).catch(function (error) {
            reject(error.response)
          })
        } else {
          return resolve(getters.findAll)
        }
      })
    }
  }
}
