import axios from 'axios'
import router from '../../router/router'
import swal from 'sweetalert2'

const accessTokenStorageName = 'access_token'
const refreshTokenStorageName = 'refresh_token'
const accountDataStorageName = 'account_data'

export default {
  state: {
    httpInterceptor: null,
    responseInterceptor: null,
    accessToken: process.browser ? localStorage.getItem(accessTokenStorageName) : null,
    refreshToken: process.browser ? localStorage.getItem(refreshTokenStorageName) : null,
    accountData: process.browser ? localStorage.getItem(accountDataStorageName) : null,
    isOffline: false
  },
  getters: {
    tokens: state => {
      return {
        // eslint-disable-next-line @typescript-eslint/camelcase
        access_token: state.accessToken,
        // eslint-disable-next-line @typescript-eslint/camelcase
        refresh_token: state.refreshToken
      }
    },
    accessToken: state => {
      return state.accessToken
    },
    refreshToken: state => {
      return state.refreshToken
    },
    accessTokenHeader: state => {
      return 'Bearer ' + state.accessToken
    },
    isLoggedIn: state => {
      return state.accessToken !== null
    },
    accountData: state => {
      return JSON.parse(state.accountData)
    },
    isOffline: state => {
      return state.isOffline
    }
  },
  mutations: {
    setHttpInterceptor(state, data) {
      state.httpInterceptor = {
        request: data.request,
        response: data.response
      }
    },
    login(state, tokens) {
      state.accessToken = tokens.accessToken
      state.refreshToken = tokens.refreshToken
      if (!tokens.account_data !== null) {
        state.accountData = tokens.accountData
      }
    },
    setAccountData(state, accountData) {
      state.accountData = accountData
    },
    checkIsOffline(state, status) {
      state.isOffline = status
    },
    logout(state) {
      state.httpInterceptor = {
        request: null,
        response: null
      }
      state.accessToken = null
      state.refreshToken = null
      state.accountData = null
    }
  },
  actions: {
    login({ dispatch }, credentials) {
      return axios
        .post(`${process.env.VUE_APP_API_URL}oauth/token`, {
          username: credentials.email,
          password: credentials.password,
          // eslint-disable-next-line @typescript-eslint/camelcase
          grant_type: 'password',
          // eslint-disable-next-line @typescript-eslint/camelcase
          client_id: process.env.VUE_APP_API_CLIENT_ID,
          // eslint-disable-next-line @typescript-eslint/camelcase
          client_secret: process.env.VUE_APP_API_CLIENT_SECRET
        })
        .then(response => {
          const a = response.data
          return dispatch('getAccountData', a.access_token)
            .then(response => {
              return dispatch('saveTokens', {
                tokens: a,
                accountData: JSON.stringify(response),
                isLocalLogin: false
              })
            })
            .catch(error => {
              return Promise.reject(error)
            })
        })
    },
    localLogin() {
      return {
        data: {
          accessToken: localStorage.getItem(accessTokenStorageName),
          refreshToken: localStorage.getItem(refreshTokenStorageName)
        }
      }
    },
    refreshToken({ state }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}oauth/token`, {
            // eslint-disable-next-line @typescript-eslint/camelcase
            grant_type: 'refresh_token',
            // eslint-disable-next-line @typescript-eslint/camelcase
            client_id: process.env.VUE_APP_API_CLIENT_ID,
            // eslint-disable-next-line @typescript-eslint/camelcase
            client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
            // eslint-disable-next-line @typescript-eslint/camelcase
            refresh_token: state.refreshToken
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    register({ dispatch }, credentials) {
      return new Promise((resolve, reject) => {
        return axios
          .post(`${process.env.VUE_APP_API_URL}registration/donatur`, {
            email: credentials.email,
            password: credentials.password,
            // eslint-disable-next-line @typescript-eslint/camelcase
            phone_number: credentials.phone_number,
            // eslint-disable-next-line @typescript-eslint/camelcase
            first_name: credentials.first_name,
            // eslint-disable-next-line @typescript-eslint/camelcase
            last_name: credentials.last_name,
            // eslint-disable-next-line @typescript-eslint/camelcase
            client_id: process.env.VUE_APP_API_CLIENT_ID,
            // eslint-disable-next-line @typescript-eslint/camelcase
            client_secret: process.env.VUE_APP_API_CLIENT_SECRET
          })
          .then(response => {
            const a = response.data
            return dispatch('getAccountData', a.access_token)
              .then(response => {
                return dispatch('saveTokens', {
                  tokens: a,
                  accountData: JSON.stringify(response),
                  isLocalLogin: false
                }).then(() => {
                  resolve()
                })
              })
              .catch(error => {
                return Promise.reject(error)
              })
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    registerWithFacebook({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        window.FB.login(response => {
          if (response.authResponse) {
            window.FB.api('/me', { fields: 'first_name,last_name,name,email' }, 'GET', function(
              resp
            ) {
              if (resp) {
                return dispatch('registerBySosmed', {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  provider_token: response.authResponse.accessToken,
                  email: resp.email,
                  password: '1234567',
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  provider_name: 'facebook',
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  phone_number: null,
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  first_name: resp.first_name,
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  last_name: resp.last_name
                })
                  .then(() => {
                    window.FB.logout(function(response) {
                      return resolve(response)
                    })
                  })
                  .catch(error => {
                    if (error.status === 400) {
                      if (
                        error.data.errors === 'Email atau No.Hp telah terdaftar, silahkan login'
                      ) {
                        return dispatch('loginWithFacebook', {
                          username: resp.email,
                          // eslint-disable-next-line @typescript-eslint/camelcase
                          provider_token: resp.id,
                          // eslint-disable-next-line @typescript-eslint/camelcase
                          provider_name: 'facebook',
                          // eslint-disable-next-line @typescript-eslint/camelcase
                          grant_type: 'social-login',
                          role: 'D',
                          // eslint-disable-next-line @typescript-eslint/camelcase
                          client_id: process.env.VUE_APP_API_CLIENT_ID,
                          // eslint-disable-next-line @typescript-eslint/camelcase
                          client_secret: process.env.VUE_APP_API_CLIENT_SECRET
                        })
                          .then(() => {
                            window.FB.logout(function(response) {
                              return resolve(response)
                            })
                          })
                          .catch(error => {
                            reject(error.response)
                          })
                      }
                    }
                  })
              }
            })
          } else {
            window.FB.login(response => {
              if (response.authResponse) {
                window.FB.api(`/me`, { fields: 'first_name,last_name,name,email' }, 'GET', function(
                  resp
                ) {
                  // Insert your code here
                  if (resp) {
                    return dispatch('registerBySosmed', {
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      provider_token: response.authResponse.accessToken,
                      email: resp.email,
                      password: '1234567',
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      provider_name: 'facebook',
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      phone_number: null,
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      first_name: resp.first_name,
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      last_name: resp.last_name
                    })
                      .then(() => {
                        window.FB.logout(function(response) {
                          return resolve(response)
                        })
                      })
                      .catch(error => {
                        if (error.status === 400) {
                          if (
                            error.data.errors === 'Email atau No.Hp telah terdaftar, silahkan login'
                          ) {
                            return dispatch('loginWithFacebook', {
                              email: resp.email,
                              // eslint-disable-next-line @typescript-eslint/camelcase
                              id: resp.id,
                              // eslint-disable-next-line @typescript-eslint/camelcase
                              grant_type: 'social-login',
                              role: 'D',
                              // eslint-disable-next-line @typescript-eslint/camelcase
                              client_id: process.env.VUE_APP_API_CLIENT_ID,
                              // eslint-disable-next-line @typescript-eslint/camelcase
                              client_secret: process.env.VUE_APP_API_CLIENT_SECRET
                            })
                              .then(() => {
                                window.FB.logout(function(response) {
                                  return resolve(response)
                                })
                              })
                              .catch(error => {
                                reject(error.response)
                              })
                          }
                        }
                      })
                  }
                })
              }
            })
          }
        })
      })
    },
    registerBySosmed({ dispatch }, credentials) {
      return new Promise((resolve, reject) => {
        return axios
          .post(`${process.env.VUE_APP_API_URL}social-registration/donatur`, {
            email: credentials.email,
            password: credentials.password,
            // eslint-disable-next-line @typescript-eslint/camelcase
            provider_name: credentials.provider_name,
            // eslint-disable-next-line @typescript-eslint/camelcase
            provider_token: credentials.provider_token,
            // eslint-disable-next-line @typescript-eslint/camelcase
            phone_number: credentials.phone_number,
            // eslint-disable-next-line @typescript-eslint/camelcase
            first_name: credentials.first_name,
            // eslint-disable-next-line @typescript-eslint/camelcase
            last_name: credentials.last_name,
            // eslint-disable-next-line @typescript-eslint/camelcase
            client_id: process.env.VUE_APP_API_CLIENT_ID,
            // eslint-disable-next-line @typescript-eslint/camelcase
            client_secret: process.env.VUE_APP_API_CLIENT_SECRET
          })
          .then(response => {
            const a = response.data
            return dispatch('getAccountData', a.access_token)
              .then(response => {
                return dispatch('saveTokens', {
                  tokens: a,
                  accountData: JSON.stringify(response),
                  isLocalLogin: false
                }).then(() => {
                  resolve()
                })
              })
              .catch(error => {
                reject(error.reponse)
              })
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    getAccountData({ state }, accessToken) {
      return axios
        .get(process.env.VUE_APP_API_URL + 'me', {
          headers: { Authorization: 'Bearer ' + accessToken }
        })
        .then(response => {
          return response.data
        })
        .catch(error => {
          console.log(error);
          return Promise.reject(error)
        })
    },
    logout({ commit, state, dispatch }) {
      localStorage.removeItem(accountDataStorageName)
      return axios
        .delete(`${process.env.VUE_APP_API_URL}oauth/token`)
        .then(() => {
          axios.interceptors.request.eject(state.httpInterceptor.request)
          axios.interceptors.response.eject(state.httpInterceptor.response)
          localStorage.removeItem(accessTokenStorageName)
          localStorage.removeItem(refreshTokenStorageName)
        })
        .catch(() => {
          localStorage.removeItem(accessTokenStorageName)
          localStorage.removeItem(refreshTokenStorageName)
          commit('logout')
        })
    },
    loginWithGoogle({ dispatch }, credentials) {
      return axios
        .post(`${process.env.VUE_APP_API_URL}oauth/token`, {
          username: credentials.username,
          // eslint-disable-next-line @typescript-eslint/camelcase
          provider_token: credentials.provider_token,
          // eslint-disable-next-line @typescript-eslint/camelcase
          provider_name: 'google',
          // eslint-disable-next-line @typescript-eslint/camelcase
          grant_type: 'social-login',
          role: 'D',
          // eslint-disable-next-line @typescript-eslint/camelcase
          client_id: process.env.VUE_APP_API_CLIENT_ID,
          // eslint-disable-next-line @typescript-eslint/camelcase
          client_secret: process.env.VUE_APP_API_CLIENT_SECRET
        })
        .then(response => {
          const a = response.data
          return dispatch('getAccountData', a.access_token)
            .then(response => {
              return dispatch('saveTokens', {
                tokens: a,
                accountData: JSON.stringify(response),
                isLocalLogin: false
              })
            })
            .catch(error => {
              return Promise.reject(error)
            })
        })
    },
    loginFacebook({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        window.FB.getLoginStatus(response => {
          if (response.authResponse) {
              window.FB.login((response) => {
                  if (response.authResponse) {
                      window.FB.api(
                        `/${response.authResponse.userID}`,
                        'GET',
                        { fields: 'birthday,email,hometown' },
                        function(result) {
                          // Insert your code here
                          if (result.id) {
                            const objCredential = {
                              accessToken: response.authResponse.accessToken,
                              username: result.email
                            }
                            return dispatch('loginWithFacebook', objCredential)
                              .then(() => {
                                resolve()
                              })
                              .catch(error => {
                                reject(error)
                              })
                          }
                        }
                      )
                      // resolve({
                      //     'user_id': response.authResponse.userID,
                      //     'access_token': response.authResponse.accessToken
                      // })
                  } else {
                      reject('Gagal terhubung dengan facebook.')
                  }
              }, {scope: 'public_profile,email'})
          } else {
            window.FB.login(
              response => {
                if (response.authResponse) {
                  window.FB.api(
                    `/${response.authResponse.userID}`,
                    'GET',
                    { fields: 'birthday,email,hometown' },
                    function(result) {
                      // Insert your code here
                      if (result.id) {
                        const objCredential = {
                          accessToken: response.authResponse.accessToken,
                          username: result.email
                        }
                        return dispatch('loginWithFacebook', objCredential)
                          .then(() => {
                            resolve()
                          })
                          .catch(error => {
                            reject(error)
                          })
                      }
                    }
                  )
                } else {
                  swal.close()
                  swal.hideLoading()
                  swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Gagal menghubungkan ke facebook'
                  })
                }
              },
              {
                scope:
                  'public_profile,email,pages_show_list,pages_read_user_content' +
                  (process.env.NODE_ENV === 'production' ? '' : '')
              }
            )
          }
        })
      })
    },
    loginWithFacebook({ dispatch }, credentials) {
      return axios
        .post(`${process.env.VUE_APP_API_URL}oauth/token`, {
          username: credentials.email,
          // eslint-disable-next-line @typescript-eslint/camelcase
          provider_token: credentials.accessToken,
          // eslint-disable-next-line @typescript-eslint/camelcase
          provider_name: 'facebook',
          // eslint-disable-next-line @typescript-eslint/camelcase
          grant_type: 'social-login',
          role: 'D',
          // eslint-disable-next-line @typescript-eslint/camelcase
          client_id: process.env.VUE_APP_API_CLIENT_ID,
          // eslint-disable-next-line @typescript-eslint/camelcase
          client_secret: process.env.VUE_APP_API_CLIENT_SECRET
        })
        .then(response => {
          const a = response.data
          return dispatch('getAccountData', a.access_token)
            .then(response => {
              return dispatch('saveTokens', {
                tokens: a,
                accountData: JSON.stringify(response),
                isLocalLogin: false
              })
            })
            .catch(error => {
              return Promise.reject(error)
            })
        })
    },
    saveTokens({ state, commit, dispatch }, params) {
      const a = params.tokens.access_token
      const b = params.tokens.refresh_token
      const c = params.accountData === null ? state.accountData : params.accountData
      if (!params.isLocalLogin) {
        localStorage.setItem(accessTokenStorageName, a)
        localStorage.setItem(refreshTokenStorageName, b)
        localStorage.setItem(accountDataStorageName, c)
      }
      commit('login', {
        accessToken: a,
        refreshToken: b,
        accountData: c
      })
      // set default timezone based on Account Data
      // moment.tz.setDefault(getters.accountData.timezone)
      const requestInterceptors = axios.interceptors.request.use(config => {
        dispatch('setIsOffline', false)
        if (state.accessToken !== null) {
          config.headers.Authorization = 'Bearer ' + state.accessToken
        }
        return config
      })
      // dispatch('initFirebase')
      const responseInterceptors = axios.interceptors.response.use(undefined, function (err) {
        console.log(err);
        if (err.response === undefined) {
          if (err.message === 'Network Error') dispatch('setIsOffline', true)
        } else {
          dispatch('setIsOffline', false)
          if (err.response.status === 401 && err.response.data.message === 'Unauthenticated.' && err.config && !err.config.__isRetryRequest) {
            return new Promise(function (resolve, reject) {
              return dispatch('refreshToken')
                .then(response => {
                  localStorage.setItem(accessTokenStorageName, response.access_token)
                  localStorage.setItem(refreshTokenStorageName, response.refresh_token)
                  commit('login', {
                    accessToken: response.access_token,
                    refreshToken: response.refresh_token,
                    accountData: state.accountData
                  })
                  err.config.__isRetryRequest = true
                  // err.config.headers.Authorization = 'Bearer ' + response.access_token
                  axios(err.config).then(resolve, reject)
                })
                .catch(() => {
                  // return router.push('/')
                  return dispatch('logout')
                  .then(() => {
                    router.push('/login')
                  })
                  // Promise.reject(null)
                })
            })
          } else {
            return Promise.reject(err)
          }
        }
      })
      commit('setHttpInterceptor', {
        request: requestInterceptors,
        response: responseInterceptors
      })
      // if (getters.accountData !== null && getters.accountData.role !== 'service.admin') {
      //   return Promise.reject('I\'m so sorry, You don\'t have permission to access on this website.')
      // }
      return responseInterceptors
    },
    setAccountData ({ commit }, value) {
      commit('setAccountData', value)
    },
    setIsOffline({ commit, dispatch }, status) {
      commit('checkIsOffline', status)
      if (status) {
        console.log('koneksi putus')
        // dispatch('notification/error', 'Koneksi putus, Silahkan cek koneksi internet anda.', {
        //   root: true
        // })
      }
    }
  }
}
